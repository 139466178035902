
import React, {useEffect, useState} from 'react';
import { Button, Row, Col, Input, Flex, Space, Typography, Card, Divider, Tooltip, Select, Switch, InputNumber, Popover, AutoComplete, Checkbox } from 'antd';
const { Text, Title } = Typography;
const {TextArea} = Input;
import { 
  MinusCircleTwoTone,
  LockOutlined,
  UnlockOutlined,
  CloseCircleTwoTone,
  SyncOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  InfoCircleTwoTone,
  DatabaseOutlined, 
  RightSquareOutlined, 
  CheckCircleTwoTone, 
  ExclamationCircleTwoTone,
  PlusCircleTwoTone
} from '@ant-design/icons';

import moment from 'moment';

import axios from 'axios';
import endpoints from '../utils/endpoints';

import SubSectionDivider from './formComponents/SubSectionDivider';

import TanslationFieldControl from './controlFormComponents/TranslationFieldControl';

const SubmissionControlForm = ({place, submission}) => {
  const [revisedFields, setRevisedFields] = useState(submission.revised_fields)
  const [originalFields, setOriginalFields] = useState({
    ...submission.original_fields,
    ...place.self_description && {
      self_description: place.self_description,
      self_description_year: place.self_description_year
    }
  })
  const [updatedFields, setUpdatedFields] = useState(submission.updated_fields)
  const [tags, setTags] = useState(place.tags?.sort() ?? [])
  const [selectOptions, setSelectOptions] = useState({})
  const [patchStatus, setPatchStatus] = useState(null)

  const publicationDefaultValue = {sdna: false, nw: false}
  const [publication, setPublication] = useState(publicationDefaultValue)
  
  const [firstEditorEmail, setFirstEditorEmail] = useState(submission?.first_editor)
  const [secondEditorEmail, setSecondEditorEmail] = useState(submission?.second_editor)
  const [submissionStatus, setSubmissionStatus] = useState(submission.status)
  const [isSubmissionStatusLocked, setIsSubmissionStatusLocked] = useState(!!firstEditorEmail || !!secondEditorEmail)

  const languageIcons = require('../utils/languageIcons.json')
  const languageOptions = Object.entries(languageIcons).map(([key, value]) => ({label: value, value: key}))
  
  const geoSituationOptions = [
    {label: "Stadt", value: "city"},
    {label: "Ländlich", value: "rural"},
    {label: "Kleinstadt", value: "town"}
  ]
  const booleanOptions = [
    {label: "Ja", value: true},
    {label: "Nein", value: false}
  ]
  const workingGuestOptions = [
    {label: "Grundsätzlich Ja", value: "yes"},
    {label: "Nein", value: "no"},
    {label: "Freie Kost & Logis", value: "food_and_shelter"}
  ]

  const translationFields = [
    {
      title: "Selbstbeschreibung",
      colName: "self_description"
    },
    {
      title: "Ideologische & spirituelle Grundsätze",
      colName: "principles"
    },
    {
      title: "Aufnahmevoraussetzungen",
      colName: "conditions"
    },
      {
      title: "Wer entscheidet",
      colName: "decision_group"
    },
      {
      title: "Entscheidungsmethode",
      colName: "decision_method"
    },
    {
      title: "Ökonomie",
      colName: "economy"
    },
    {
      title: "Besitzverhältnisse",
      colName: "owned_by"
    },
    {
      title: "Haupttätigkeitsbereiche",
      colName: "work_fields"
    },
    {
      title: "Schlagwörter",
      colName: "custom_keywords"
    },
    {
      title: "Größe des Anwesens",
      colName: "size"
    },
    {
      title: "Sprachkenntnisse",
      colName: "language_skills"
    },
    {
      title: "Sonstige Netzwerke",
      colName: "other_networks"
    },
    {
      title: "Mitarbeitende in Gemeinschaftsbetrieben",
      colName: "people_working_in_common_businesses"
    },
    {
      title: "Personen, die ihren (beruflichen) Alltag in der Gemeinschaft verbringen",
      colName: "people_working_on_community_grounds"
    },
    {
      title: "Grad der Selbstversorgung",
      colName: "self_sufficiency"
    },
    {
      title: "Ernährung",
      colName: "dietary_practice"
    }
  ]

  const getTranslationLangs = () => {
    // get all languages from originalFields and updatedFields
    const originalTranslationFields = _.pick(originalFields, _.map(translationFields, "colName"))
    const updatedTranslationFields = _.pick(updatedFields, _.map(translationFields, "colName"))
    const originalLangs = _.uniq([
      ..._.flatten(_.map(originalTranslationFields, (field) => _.keys(field))),
      ..._.flatten(_.map(updatedTranslationFields, (field) => _.keys(field))),
      'en',
      'de'
    ])
    //sort the languages in the following order: de, en, rest
    const sortedLangs = _.sortBy(originalLangs, (lang) => {
      switch (lang) {
        case "de":
          return 0
        case "en":
          return 1
        default:
          return 2
      }
    })
    return sortedLangs
  }
  
  const [translationLangs, setTranslationLangs] = useState(getTranslationLangs())

  const showPatchNotification = async ({status}) => {
    setPatchStatus(status)
    setTimeout(() => {
      setPatchStatus(null)
    }, 3000)
  }

  const fetchAndUpdateCurrentRevisionState = async ()  => {
    const response = await axios.get(endpoints.revision, {
      params: {
        uuid: submission.uuid,
        placeId: submission.place
      }
    })
    const currentRevisedFields = response.data.value
    setRevisedFields(currentRevisedFields)
  }

  const handleRevision = async (columnName, value) => {
    try {
      let revision = await axios.post(endpoints.revision, {
        columnName,
        value,
        editMode: true,
        passcode: submission.passcode,
        uuid: submission.uuid,
        placeId: submission.place
      })
      await fetchAndUpdateCurrentRevisionState()
      return revision.data.success
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const sendSubmissionPatch = async({patch}) => {
    setPatchStatus("running")
    try {
      await axios.patch(endpoints.submission, {
        patch,
        passcode: submission.passcode,
        editMode: true,
        uuid: submission.uuid
      })
      await showPatchNotification({status: "success"})
    } catch (error) {
      console.error(error)
      await showPatchNotification({status: "error"})
    }
  }
  
  const sendPlacePatch = async({patch}) => {
    setPatchStatus("running")
    try {
      await axios.patch(endpoints.place, {
        patch,
        uuid: submission.uuid,
        passcode: submission.passcode,
        editMode: true,
        id: submission.place
      })
      await showPatchNotification({status: "success"})
    } catch (error) {
      console.error(error)
      await showPatchNotification({status: "error"})
    }
  }

  useEffect(() => {
    const fetchSelectOptions = async () => {
      const response = await axios.get(endpoints.selectOptions)
      setSelectOptions(response.data)
    }
    fetchSelectOptions()
  }, [])

  useEffect(() => {
    const fetchPublicationData = async () => {
      const response = await axios.get(endpoints.publication, {
        params: {
          placeId: submission.place,
          editionId: submission.edition
        }
      })
      if (!_.isEmpty(response.data)) setPublication(response.data.publication)
    }
    fetchPublicationData()
  }, [])

  useEffect(() => {
    setIsSubmissionStatusLocked(!!firstEditorEmail || !!secondEditorEmail)
  }, [firstEditorEmail, secondEditorEmail])
  
  const FormControl = ({colName, colLabel, compType, options}) => {
    const [updatedValue, setUpdatedValue] = useState(updatedFields[colName])
    const [revisedValue, setRevisedValue] = useState(revisedFields?.[colName])
    const [originalValue, setOriginalValue] = useState(originalFields[colName])
    const [currentRevision, setCurrentRevision] = useState(revisedValue ?? updatedValue)
    const hasBeenUpdated = _.keys(updatedFields).includes(colName)
    const IconCol = ({iconComp, isVisible, tooltipHeader, tooltipText}) => {
      return (
        <Col flex="50px" style={{...isVisible ? {} : {visibility:"hidden"}}}>
          <Tooltip
            title={
              <Space direction="vertical">
                <Text style={{color:"white"}} strong>{tooltipHeader}</Text>
                <Text style={{color:"white"}}>{tooltipText}</Text>
              </Space>
            }
            >
            <Flex justify='center'>
              {iconComp}
            </Flex>
          </Tooltip>
        </Col>
      )
    }

    const display = (value) => {
      switch (compType) {
        case "select":
          return options?.find(o => o.value === value)?.label
        default:
          return value
      }
    }
    
    let updatedValueComp;
    // TODO: move states inside this component (?)
    // check autofocus issue;
    // https://stackoverflow.com/questions/59715158/react-hooks-input-loses-focus-when-1-character-is-typed-in
    const commonInputProps = {
      style: {flex: 1, ...(hasBeenUpdated ? {}: {visibility: "hidden"})},
      value: currentRevision,
      addonBefore: <DiffOutlined />,
      onChange: (e) => {
        setCurrentRevision(e.target.value)
      }
    }

    switch (compType) {
      case "text":
        updatedValueComp = (
          <Input 
            {...commonInputProps}
            onChange = {(e) => {
              setCurrentRevision(e.target.value)
            }}
          />
        )
        break;
      case "number":
        updatedValueComp =  (
          <InputNumber 
            {...commonInputProps}
            onChange = {(value) => {
              setCurrentRevision(value)
            }}
          />
        );
        break;
      case "select":
        updatedValueComp = (
          <Space.Compact style={{flex: 1, ...(hasBeenUpdated ? {}: {visibility: "hidden"})}}>
            <Input
              style={{ width: '38px', borderRight: 0, pointerEvents: 'none', backgroundColor: '#fff' }}
              addonBefore={<DiffOutlined />}
            />
            <Select 
              style={{ width: 'calc(100% - 38px)' }}
              options={options}
              onChange={(value) => {setCurrentRevision(value)}}
              defaultValue={revisedValue ?? updatedValue}
            />
          </Space.Compact>
        )
        break;
      default:
        updatedValueComp = (
          <Col flex="auto">
            <>ColType missing</>
          </Col>
        )
    }

    const handleRevisionClick = async () => {
      let revisionValue = compType === "number" && currentRevision ? parseInt(currentRevision) : currentRevision
      const revisionSuccess = await handleRevision(colName, revisionValue)
      revisionSuccess && setRevisedValue(currentRevision)
    }
    
    return (
      <Flex align="center">
        <LabelCol label={colLabel}/>
        <IconCol
          iconComp={
            revisedValue === updatedValue
            ? <CheckCircleTwoTone twoToneColor="#60D394"/>
            : !_.isUndefined(revisedValue)
            ? <CheckCircleTwoTone twoToneColor="#F5AB00"/>
            : <ExclamationCircleTwoTone twoToneColor="#EE6055"/>
          }
          isVisible={!!updatedValue}
          tooltipHeader={
            !_.isUndefined(revisedValue) && revisedValue !== updatedValue 
            ? "Ursprünglicher Wert aus der Einsendung:"
            : revisedValue === undefined
            ? "Wert muss noch übertragen werden"
            : "Wert 1:1 übertragen"
          }
          tooltipText={
            !_.isUndefined(revisedValue) && revisedValue !== updatedValue 
            ? display(updatedValue)
            : ""
          }
        />
        <Col flex="auto">
          <Flex gap={10}>
            {updatedValueComp}
            <Flex justify='center' style={{width:"70px", ...(hasBeenUpdated ? {} : {visibility: "hidden"})}}>
              <Button 
                type={!_.isUndefined(revisedValue) ? "dashed" : "primary"}
                icon={<RightSquareOutlined />} 
                onClick={handleRevisionClick}
              />
            </Flex>
            <Input 
              readOnly={true} 
              style={{flex:1}}
              value={display(!_.isUndefined(revisedValue) ? revisedValue : originalValue)} 
              addonBefore={<DatabaseOutlined />}
            />
          </Flex>
        </Col>
        <IconCol
          iconComp={<InfoCircleTwoTone twoToneColor="#ADADAD"/>}
          isVisible={!_.isUndefined(revisedValue)}
          tooltipHeader="Ursprünglicher Wert aus der Datenbank:"
          tooltipText={display(originalValue)}
        />
      </Flex>
    )
  }

  const addressRows = [
    {colName: "name", colLabel: "Name"},
    {colName: "byname", colLabel: "Namenszusatz"},
    {colName: "synonym", colLabel: "Synonym"},
    {colName: "address", colLabel: "Adresse"},
    {colName: "city", colLabel: "Stadt"},
    {colName: "postal_code", colLabel: "PLZ"},
    {colName: "country_code", colLabel: "Land"},
  ]

  const publicContactRows = [
    {colName: "contact_name", colLabel: "Ansprechpartner*in"},
    {colName: "telephone", colLabel: "Telefon"},
    {colName: "public_email", colLabel: "Öffentliche E-Mail"},
    {colName: "contact_emails", colLabel: "E-Mail (privat)"},
    {colName: "website", colLabel: "Website"},
    {colName: "public_messenger_number", colLabel: "Messenger Nummer"},
    {colName: "public_messenger_service", colLabel: "Messenger Service"}
  ]

  const LabelCol = ({label, noIcon=false}) => {
    return (
      <Col flex={noIcon ? "200px" : "150px"}>
        <Text strong>{label}</Text>
      </Col>
    )
  }

  const TagControl = () => {
    const TagWrapper = ({children}) => {
      return (
        <Space 
          direction='horizontal'
          size={4}
          style={{
            padding: "2px 3px 2px 3px",
            border: "1px solid #dcdcdc",
            borderRadius: "5px",
            backgroundColor: "#f0f0f0",
            cursor: "default"
          }}
        >
          {children}
        </Space>
      )
    }

    const CustomTag = ({tagId}) => {
      const [hoverActive, setHoverActive] = useState(false)
      const tagName = selectOptions?.["tags"]?.find(t => t.id === tagId)?.name
      return (
        <TagWrapper>
          <Text style={{fontSize:"0.9em"}}>{tagName}</Text>
          {
            patchStatus === "running" && <SyncOutlined color={"#adadad"} spin/>
          }
          {
            patchStatus !== "running" && <CloseCircleTwoTone
              onMouseEnter={() => setHoverActive(true)}
              onMouseLeave={() => setHoverActive(false)}
              twoToneColor={hoverActive ? "#EE6055" : "#adadad"}
              onClick={async () => {
                const newTagsArray = tags.filter(t => t !== tagId)
                try {
                  await sendPlacePatch({
                    patch: {tags: newTagsArray}
                  })
                  setTags(newTagsArray)
                } catch (error) {
                  console.error(error)
                }
              }}
            />
          }
        </TagWrapper>
      )
    }

    const AddTagPopover = () => {
      const [searchValue, setSearchValue] = useState("")
      const [hoverActive, setHoverActive] = useState(false)
      const handleNewTag = async (value) => {
        try {
          const newTagsArray = [...tags, value]
          await sendPlacePatch({
            patch: {tags: newTagsArray}
          })
          setTags(newTagsArray)
        } catch (error) {
          console.error(error)
        }
      }

      return (
        <TagWrapper>
          <Popover
            onOpenChange={() => setSearchValue("")}
            content={
              <AutoComplete
                options={
                  selectOptions?.["tags"]
                    ?.filter(tag => !tags.includes(tag.name))
                    ?.map(tag => ({value: tag.id, label: tag.name}))
                    ?.filter(tag => tag.value.includes(searchValue))
                }
                style={{ width: 300 }}
                onSelect={(value) => handleNewTag(value)}
              >
                <Input.Search 
                  placeholder="Tag suchen.." 
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </AutoComplete>
            }
          >
            <PlusCircleTwoTone
              onMouseEnter={() => setHoverActive(true)}
              onMouseLeave={() => setHoverActive(false)}
              twoToneColor={hoverActive ? "#60D394" : "#adadad"}
            />
          </Popover>
        </TagWrapper>
        )
      }

    return (
      <Space wrap>
        {tags.map((tagId, index) => (
          <CustomTag key={index} tagId={tagId}/>
        ))}
        <AddTagPopover/>
      </Space>
    )
  }

  const KeywordControl  = () => {
    const displayedTags = _.uniq([...(updatedFields?.keywords || []), ...(originalFields?.keywords || [])])
    const areKeywordsUpdated = !!updatedFields?.keywords
    const areKeywordsRevised = !!revisedFields?.keywords
    const [patchOperationRunning, setPatchOperationRunning] = useState(false)
    
    return (
      <Row>
        <LabelCol noIcon label="Schlagworte"/>
        <Col flex={"450px"}>
          <Space direction='vertical' style={{width:"100%"}} size={4}>
            {displayedTags.map((keywordId, index) => {
              const keyword = selectOptions?.["keywords"]?.find(k => k.id === keywordId)
              const updatedValue = updatedFields?.keywords?.includes(keywordId)
              const originalValue = originalFields?.keywords?.includes(keywordId)
              const status = !areKeywordsUpdated
              ? "bleibt" 
              : updatedValue === originalValue 
              ? "bleibt" 
              : updatedValue 
              ? "hinzugefügt" 
              : "entfernt"
              return (
                <div key={index}>
                  <Flex gap={4} align='center' justify="space-between" key={index}>
                    <Space style={{width: "40%"}} size={8} direction="horizontal">
                      {status == "hinzugefügt" && <PlusCircleTwoTone twoToneColor={"#60D394"}/>}
                      {status == "entfernt" && <CloseCircleTwoTone twoToneColor={"#EE6055"}/>}
                      {status == "bleibt" && <MinusCircleTwoTone twoToneColor={"#9b9b9b"}/>}
                      <Text key={index}>{keyword?.de}</Text>
                    </Space>
                    <Text style={{flex: 1, textAlign: "end"}} strong key={index}>{status}</Text>
                  </Flex>
                  <Divider style={{marginTop: "5px", marginBottom: "5px"}}/>
                </div>
              );
            })
          }
          </Space>
          <Button
            loading={patchOperationRunning}
            style={{width: "100%"}} 
            danger={!areKeywordsRevised}
            disabled={areKeywordsRevised || patchOperationRunning || !areKeywordsUpdated}
            onClick={async () => {
              setPatchOperationRunning(true)
              try {
                await handleRevision("keywords", updatedFields.keywords)
              } catch(e) {
                console.error(e)
              } finally {
                setPatchOperationRunning(false)
              }
            }}
            icon={
              !areKeywordsRevised && areKeywordsUpdated 
              ? <ExclamationCircleOutlined/> 
              : <CheckCircleTwoTone twoToneColor={"#60D394"} />
            } >
              {!areKeywordsUpdated ? "Keine Änderung" : areKeywordsRevised ? "Revision gespeichert" : "Revision speichern"}
            </Button>
        </Col>
      </Row>
    )
  }

  const PublicationCheckbox = ({colName}) => {
    const [checked, setChecked] = useState(publication?.[colName] ?? false)

    return (
      <Checkbox
        defaultChecked={checked}
        onChange={async (value) => {
          const newPublication = {..._.pick(publication,["sdna", "nw"]), [colName]: value.target.checked}
          setPatchStatus("running")
          try {
            await axios.post(endpoints.publication, {
              uuid: submission.uuid,
              passcode: submission.passcode,
              editMode: true,
              updatedFields: newPublication,
              placeId: submission.place,
              editionId: submission.edition
            })
            await showPatchNotification({status: "success"})
            setPublication(newPublication)
            setChecked(value)
          } catch (error) {
            console.error(error)
            await showPatchNotification({status: "error"})
          }
        }}
      > 
        {colName.toUpperCase()} 
      </Checkbox>
    )
  }

  const PublicationControl = () => {
    return (
      <Row>
        <LabelCol noIcon label="Veröffentlichung"/>
        <Col flex={"auto"}>
          <Space direction="horizontal" size={4}>
            <PublicationCheckbox colName="sdna"/>
            <PublicationCheckbox colName="nw"/>
          </Space>
        </Col>
      </Row>
    )
  }

  const EditorEmailSelect = ({email, setEmail, colName, props}) => {
    const handleSelectChange = async (value) => {
      try {
        await sendSubmissionPatch({
          patch: {
            [colName]: value ?? null,
          }
        })
        setEmail(value)
        const firstEmailSet = colName === "first_editor" ? !!value : !!firstEditorEmail
        const secondEmailSet = colName === "second_editor" ? !!value : !!secondEditorEmail
        const newStatus = !secondEmailSet && firstEmailSet 
        ? selectOptions?.submission_status?.find(s => s.code === "reviewed").id
        : secondEmailSet ? selectOptions?.submission_status?.find(s => s.code === "done").id
        : selectOptions?.submission_status?.find(s => s.code === "submitted").id
        
        if (newStatus !== submissionStatus) {
          await sendSubmissionPatch({patch: {status: newStatus}})
          setSubmissionStatus(newStatus)
        }
      } catch (error) {
        console.error(error)
      }
    }
    return (
      <Select 
        options={selectOptions?.["editors"]?.map(o => ({label: o.name, value: o.id}))}
        onChange={handleSelectChange}
        value={email}
        {...props}
      />
    )
  }
  const SubmissionStatusControl = () => {
    return (
      <>
      <Row>
        <LabelCol noIcon label="Status"/>
        <Col flex={"auto"}>
          <Flex
            gap={8}
            style={{width:"100%"}}
          >
            <Select 
              options={selectOptions?.["submission_status"]?.map(o => ({label: o.name, value: o.id}))}
              style={{width:"100%"}}
              onChange={async (value) => {
                try {
                  await sendSubmissionPatch({
                    patch: {status: value}
                  })
                  setSubmissionStatus(value)
                } catch (error) {
                  console.error(error)
                }
              }}
              value={submissionStatus}
              disabled={isSubmissionStatusLocked}
              loading={patchStatus === "running"}
            />
            <Button 
              icon={isSubmissionStatusLocked ? <UnlockOutlined /> : <LockOutlined />}
              onClick={() => setIsSubmissionStatusLocked(isSubmissionStatusLocked ? false : true)}
            />
          </Flex>
        </Col>
      </Row>
      <Row>
        <LabelCol noIcon label="Ersterfasser:in"/>
        <Col flex={"auto"}>
          <EditorEmailSelect 
            email={firstEditorEmail} 
            setEmail={setFirstEditorEmail}
            colName={"first_editor"}
            props={{
              disabled: !!secondEditorEmail,
              loading: patchStatus === "running",
              allowClear: true,
              style: {width:"100%"}
            }}
          />
        </Col>
      </Row>
      <Row>
        <LabelCol noIcon label="Zweiterfasser:in"/>
        <Col flex={"auto"}>
          <EditorEmailSelect 
            email={secondEditorEmail} 
            setEmail={setSecondEditorEmail}
            colName={"second_editor"}
            props={{
              disabled: !firstEditorEmail,
              loading: patchStatus === "running",
              allowClear: true,
              style: {width:"100%"}
            }}
          />
        </Col>
      </Row>
      </>
    )
  }
  
  return (
    <Space direction="vertical" style={{width:"100%", paddingLeft:"20px", paddingRight:"20px"}}>
      <Card title={
        <Flex justify="space-between">
          <Title level={3}>
            Einsendung von {place.name}
          </Title>
          {patchStatus === "running"
          ? <SyncOutlined spin/>
          : patchStatus === "success"
          ? <CheckCircleTwoTone twoToneColor="#60D394"/>
          : patchStatus === "error"
          ? <ExclamationCircleTwoTone twoToneColor="#EE6055"/>
          : null
          }
        </Flex>}>
      <Row gutter={16}>
        <Col span={8}>
          <SubSectionDivider title="Place Details"/>
          <Flex vertical gap={12}>
            <Row>
              <LabelCol noIcon label="Nummer"/>
              <Col flex={"auto"}>
                <Text>{place.number}</Text>
              </Col>
            </Row>
            <Row>
              <LabelCol noIcon label="Vorherige Bezeichnungen"/>
              <Col flex={"auto"}>
                <Input
                  defaultValue={place?.previous_names}
                  onBlur={async (e) => {
                    await sendPlacePatch({
                      patch: {previous_names: e.target.value}
                    })
                  }}
                />
              </Col>
            </Row>
            <Row wrap={false}>
              <LabelCol noIcon label="Notiz (Place)"/>
              <Col flex={"auto"}>
                <TextArea
                  autoSize
                  defaultValue={place?.internal_note}
                  onBlur={async (e) => {
                    await sendPlacePatch({
                      patch: {internal_note: e.target.value}
                    })
                  }}
                />
              </Col>
            </Row>
            <Row>
              <LabelCol noIcon label="Quelle"/>
              <Col flex={"auto"}>
                <Text>{place?.source || " - "}</Text>
              </Col>
            </Row>
            <Row>
              <LabelCol noIcon label="Marketing Partner"/>
              <Col flex={"auto"}>
                <Switch 
                  defaultChecked={place.is_marketing_partner}
                  loading={patchStatus === "running"}
                  onChange={async (value) => {
                    await sendPlacePatch({
                      patch: {is_marketing_partner: value}
                    })
                  }}
                />
              </Col>
            </Row>
            <Row>
              <LabelCol noIcon label="Opt-Out"/>
              <Col flex={"auto"}>
                <Switch 
                  defaultChecked={place.opt_out}
                  loading={patchStatus === "running"}
                  onChange={async (value) => {
                    await sendPlacePatch({
                      patch: {opt_out: value}
                    })
                  }}
                />
              </Col>
            </Row>
            <Row>
              <LabelCol noIcon label="Web Opt-Out"/>
              <Col flex={"auto"}>
                <Switch 
                  defaultChecked={place.web_opt_out}
                  loading={patchStatus === "running"}
                  onChange={async (value) => {
                    await sendPlacePatch({
                      patch: {web_opt_out: value}
                    })
                  }}
                />
              </Col>
            </Row>
            <Row wrap={false}>
              <LabelCol noIcon label="Tags"/>
              <Col flex={"auto"}>
              <TagControl/>
              </Col>
            </Row>
          </Flex>
        </Col>
        <Col span={8}>
          <SubSectionDivider title="Einsendungsdetails"/>
          <Flex vertical gap={12}>
            <Row>
              <LabelCol noIcon label="Ausgabe"/>
              <Col flex={"auto"}>
                <Text>{selectOptions?.["editions"]?.find(e => e.id === submission.edition)?.year}</Text>
              </Col>
            </Row>
            <Row>
              <LabelCol noIcon label="Einsendedatum"/>
              <Col flex={"auto"}>
                <Text>{moment(submission.submitted_at).format("DD.MM.YYYY HH:mm")}</Text>
              </Col>
            </Row>
            <Row>
              <LabelCol noIcon label="Letztes Update"/>
              <Col flex={"auto"}>
                <Text>{moment(submission.updated_at).format("DD.MM.YYYY HH:mm")}</Text>
              </Col>
            </Row>
            <Row>
              <LabelCol noIcon label="Einsender:in"/>
              <Col flex={"auto"}>
                <Text>{submission.submission_contact_name}</Text>
              </Col>
            </Row>
            <Row wrap={false}>
              <LabelCol noIcon label="Nachricht"/>
              <Col flex={"auto"}>
                <TextArea
                  autoSize
                  defaultValue={submission.message}
                  variant='filled'
                  readOnly
                />
              </Col>
            </Row>
            {
              place?.linked_networks &&
              <>
                <SubSectionDivider title="Netzwerke"/>
                <Flex vertical gap={4}>
                  {place.linked_networks.map((network, index) => (
                    <Text key={index}>{network.display_name}</Text>
                  ))}
                </Flex>
              </>
            }
          </Flex>
        </Col>
        <Col span={8}>
          <SubSectionDivider title="Veröffentlichung"/>
          <Flex vertical gap={12}>
            <Row>
              <LabelCol noIcon label="Print Opt-In"/>
              <Col flex={"auto"}>
                <Switch defaultChecked={submission.print_opt_in} disabled/>
              </Col>
            </Row>
            <Row>
              <LabelCol noIcon label="Web Opt-In"/>
              <Col flex={"auto"}>
                <Switch defaultChecked={submission.web_opt_in} disabled/>
              </Col>
            </Row>
            <Row>
              <LabelCol noIcon label="Will keine Daten senden"/>
              <Col flex={"auto"}>
                <Switch style={{...submission.temp_opt_out && {backgroundColor: "red"}}} defaultChecked={submission.temp_opt_out} disabled/>
              </Col>
            </Row>
            <Divider style={{marginTop:0, marginBottom:0}}/>
            <SubmissionStatusControl />
            <PublicationControl/>
            <Row wrap={false}>
              <LabelCol noIcon label="Notiz (Einsendung)"/>
              <Col flex={"auto"}>
              <TextArea
                defaultValue={submission.internal_note}
                disabled={patchStatus === "running"}
                onBlur={async (e) => {
                  await sendSubmissionPatch({
                    patch: {internal_note: e.target.value}
                  })
                }}
              />
              </Col>
            </Row>
          </Flex>
        </Col>
      </Row>
      </Card>
      <Card title="Basisdaten" >
        <Space direction="vertical" style={{width:"100%"}} size="middle">
          <FormControl 
            colName="status" 
            colLabel="Status" 
            compType={"select"}
            options={selectOptions?.["place_status"]?.map(o => ({label: o.de, value: o.id}))}
          />
          <Divider style={{margin:"0px"}}/>
          <FormControl 
            colName="type" 
            colLabel="Adresstyp" 
            compType={"select"}
            options={selectOptions?.["place_types"]?.map(o => ({label: o.name, value: o.id}))}
          />
        </Space>
      </Card>
      <Card title="Adressdaten" >
        <Space direction="vertical" style={{width:"100%"}} size="middle">
          {
            addressRows.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  {index > 0 ? <Divider style={{margin:"0px"}}/> : null}
                  <FormControl colName={row.colName} colLabel={row.colLabel} compType={"text"}/>
                </React.Fragment>
              )
            })
          }
        </Space>
      </Card>
      <Card title="Kontaktdaten" >
        <Space direction="vertical" style={{width:"100%"}} size="middle">
          {
            publicContactRows.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  {index > 0 ? <Divider style={{margin:"0px"}}/> : null}
                  <FormControl key={index} colName={row.colName} colLabel={row.colLabel} compType={"text"}/>
                </React.Fragment>
              )
            })
          }
          <FormControl 
            colName="language" 
            colLabel="Korrespondenzsprache" 
            compType={"select"} 
            options={selectOptions?.["languages"]?.map((l) => ({value: l.id, label: l.name_de}))}/>
        </Space>
      </Card>
      <Card title="Über die Gemeinschaft" >
        <Space direction="vertical" style={{width:"100%"}} size="middle">
          <FormControl colName="women_count" colLabel="Anzahl Frauen" compType={"number"}/>
          <FormControl colName="men_count" colLabel="Anzahl Männer" compType={"number"}/>
          <FormControl colName="under18_count" colLabel="Kinder / Jugendliche" compType={"number"}/>
          <FormControl colName="total_people_count" colLabel="Gesamtanzahl Personen" compType={"number"}/>
          <FormControl colName="open_to_new_members" colLabel="Neue Mitglieder erwünscht" compType={"select"} options={booleanOptions}/>
          <FormControl colName="geo_situation" colLabel="Lage" compType={"select"} options={geoSituationOptions}/>
          <FormControl colName="cities" colLabel="Städte in der Nähe" compType={"text"}/>
          <FormControl colName="exists_since_year" colLabel="Gründungsjahr" compType={"text"}/>
          <FormControl colName="is_ecovillage" colLabel="Ökodorf" compType={"select"} options={booleanOptions}/>
          <FormControl colName="working_guest_conditions" colLabel="Mitarbeitende Gäste" compType={"select"} options={workingGuestOptions}/>
          <FormControl colName="own_publications" colLabel="Eigene Publikationen" compType={"text"}/>
          <Divider/>
          <KeywordControl />
        </Space>
      </Card>
      <Card title={
        <Flex justify="space-between" align="center">
          <span>Übersetzungen</span>
          <Checkbox.Group 
            defaultValue={translationLangs} 
            options={languageOptions}
            onChange={(value) => setTranslationLangs(value)}
          />
        </Flex>}
      >
        {translationFields.map((field, index) => (
          <TanslationFieldControl 
            key={index} 
            passcode={submission.passcode}
            uuid={submission.uuid}
            placeId={submission.place}
            colName={field.colName} 
            colLabel={field.title}
            _originalValue={field.colName === "self_description" ? place.self_description : originalFields?.[field.colName]}
            _updatedValue={updatedFields?.[field.colName]}
            _revisedValue={revisedFields?.[field.colName]}
            langs={translationLangs}
            extra={field.colName === "self_description" && place.self_description_year}
          />
        ))}
      </Card>
      <Card title="Veröffentlichung" >
        <Flex vertical gap={12}>
          <Row>
            <LabelCol noIcon label="Tags"/>
            <TagControl />
          </Row>
          <SubmissionStatusControl />
          <PublicationControl />
        </Flex>
      </Card>
      <div style={{height:"50px"}}/>
    </Space>
  );
}

export default SubmissionControlForm;